import { Divider, Stack, Switch, TextField, Typography } from "@mui/material";
import * as React from "react";
import { resetAircraftCache } from "../../../hooks/api";
import { FBO } from "../../../types";
import { NoseInThreshholds } from "./NoseInThreshholds";

type SpacingSettingsProps = {
  title: string;
  horizontalSpacing: number;
  verticalSpacing: number;
  overlap: boolean;
  onChange: (field: string, value: any) => void;
};

const SpacingSettings: React.FC<SpacingSettingsProps> = ({
  title,
  horizontalSpacing,
  verticalSpacing,
  overlap,
  onChange,
}) => (
  <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
    <Typography>{title}</Typography>
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={{ width: "100%" }}
    >
      <TextField
        type="number"
        fullWidth
        inputProps={{ min: 0, max: 15, step: 0.5 }}
        size="small"
        label="Horizontal Spacing (ft)"
        onChange={(evt) => {
          onChange("sop_horizontal_spacing", parseFloat(evt.target.value));
        }}
        value={horizontalSpacing || ""}
      />
      <TextField
        type="number"
        fullWidth
        inputProps={{ min: 0, max: 15, step: 0.5 }}
        size="small"
        label="Vertical Spacing (ft)"
        onChange={(evt) =>
          onChange("sop_vertical_spacing", parseFloat(evt.target.value))
        }
        value={verticalSpacing || ""}
      />
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Typography>Allow Overlap?</Typography>
        <Switch
          checked={overlap}
          onChange={(evt) => onChange("sop_overlap", evt.target.checked)}
        />
      </Stack>
    </Stack>
  </Stack>
);

type Props = {
  fbo: FBO;
  setFBO: (fbo: FBO) => void;
  updateSOP: (field: string, value: any) => Promise<void>;
};

export const StackingSOPsPresenter: React.FC<Props> = ({
  fbo,
  setFBO,
  updateSOP,
}) => (
  <Stack direction="column" spacing={1} sx={{ pt: 2, width: 600 }}>
    <Typography variant="subtitle1">Stacking</Typography>
    <Divider />
    <SpacingSettings
      title="Normal Spacing"
      horizontalSpacing={fbo.sop_horizontal_spacing}
      verticalSpacing={fbo.sop_vertical_spacing}
      overlap={fbo.sop_overlap}
      onChange={(field, value) => {
        updateSOP(`${field}`, value);
        resetAircraftCache();
      }}
    />
    <SpacingSettings
      title="Loose Spacing"
      horizontalSpacing={fbo.sop_horizontal_spacing_loose}
      verticalSpacing={fbo.sop_vertical_spacing_loose}
      overlap={fbo.sop_overlap_loose}
      onChange={(field, value) => {
        updateSOP(`${field}_loose`, value);
        resetAircraftCache();
      }}
    />
    <SpacingSettings
      title="Tight Spacing"
      horizontalSpacing={fbo.sop_horizontal_spacing_tight}
      verticalSpacing={fbo.sop_vertical_spacing_tight}
      overlap={fbo.sop_overlap_tight}
      onChange={(field, value) => {
        updateSOP(`${field}_tight`, value);
        resetAircraftCache();
      }}
    />
    <br />
    <NoseInThreshholds fbo={fbo} setFBO={setFBO} />
  </Stack>
);

import React, { useEffect } from "react";

const useCanvas = (canvasRef, draw) => {
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    draw(context);

    return () => {};
  }, [canvasRef, draw]);
  // only do the translation one time
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.translate(0.5, 0.5);
  }, []);

  return canvasRef;
};

const Canvas = React.forwardRef((props, ref) => {
  const { draw, width, height, ...rest } = props;
  const canvasRef = useCanvas(ref, draw);

  return (
    <canvas
      id="canvas"
      ref={canvasRef}
      width={width}
      height={height}
      {...rest}
    />
  );
});

export default Canvas;

/**
import React, { useEffect, ForwardedRef, useRef } from "react";

interface CanvasProps extends React.CanvasHTMLAttributes<HTMLCanvasElement> {
  draw: (context: CanvasRenderingContext2D) => void;
}

const useCanvas = (canvasRef: ForwardedRef<HTMLCanvasElement>, draw: (context: CanvasRenderingContext2D) => void) => {
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    if (canvas && context) {
      draw(context);
    }
    return () => {};
  }, [canvasRef, draw]);

  return canvasRef;
};

const Canvas = React.forwardRef<HTMLCanvasElement, CanvasProps>((props, ref) => {
  const { draw, ...rest } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useCanvas(ref || canvasRef, draw);

  return <canvas id="canvas" ref={canvasRef} {...rest} />;
});
 */

import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import PrintIcon from "@mui/icons-material/Print";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import html2canvas from "html2canvas";
import * as React from "react";
import { useReactToPrint } from "react-to-print";
import { useMultiHangarState } from "../../containers/MultiHangarContainer";
import { useSnackbar } from "../../containers/SnackbarContainer";
import { copyImageAndUrlToClipboard } from "../../hooks/utils";
import { HangarStack } from "../../screens/Hangar";
import { HOLDING_AREA_ID } from "../../screens/MultiHangar/HoldingArea";
import { INFO_AND_CURRENT_AIRCRAFT_WIDTH } from "../../screens/Ramp/RampPresenter";
import { Hangar, Location, Ramp, Stack as StackType } from "../../types";
import { uuidv4 } from "../../utils";
import mixpanel from "../../utils/mixpanel";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { Loading } from "../Loading";
import { DRAWER_WIDTH } from "../Nav/SideDrawer";
import { SplitButton } from "../SplitButton";
import { FavoritesButton } from "./FavoritesButton";
import { MultiHangarSelect } from "./MultiHangarSelect";
import { ViewSettings } from "./ViewSettings";

type Props = {
  location: Location<Ramp | Hangar>;
  setLocation: (location: Location<Ramp | Hangar>) => void;
  stack: StackType;
  setStack: (stack: StackType) => void;
  setStacks: (stacks: HangarStack[]) => void;
  onClickUndo: () => void;
  renderOnPrint: (idsToPrint?: string[]) => React.ReactNode;
};

export const LayoutToolbar: React.FC<Props> = ({
  location,
  setLocation,
  stack,
  setStack,
  setStacks,
  onClickUndo,
  renderOnPrint,
}) => {
  const [fireFoxError, setShowFireFoxError] = React.useState<boolean>(false);
  const [confirm, setConfirm] = React.useState<{
    open: boolean;
    text: string;
    okText: string;
    onOk: (ids?: string[], dumpTo?: string) => void;
    renderSecondaryAction?: boolean;
  }>({
    open: false,
    text: "",
    okText: "",
    onOk: () => {},
    renderSecondaryAction: false,
  });
  const theme = useTheme();
  const [idsToPrint, setIdsToPrint] = React.useState<string[]>([]);
  const {
    isMultiHangar,
    activeLocationId,
    hangarIds,
    hangars,
    setHangars,
  } = useMultiHangarState();

  const [showMultiHangarPrint, setShowMultiHangarPrint] = React.useState<
    boolean
  >(false);
  const [showMultiHangarCopy, setShowMultiHangarCopy] = React.useState<boolean>(
    false
  );
  const snackbar = useSnackbar();

  const [print, setPrint] = React.useState<boolean>(false);
  const [copy, setCopy] = React.useState<boolean>(false);

  const componentRef = React.useRef();
  const copyRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        pt: 1,
        pl: 1,
        maxWidth: `calc(100vw - ${DRAWER_WIDTH}px - ${INFO_AND_CURRENT_AIRCRAFT_WIDTH}px - 2px - 8px)`,
        zIndex: theme.zIndex.modal - 1,
      }}
    >
      <ViewSettings locationType={location.type} />
      <Stack direction="row" alignItems="center" spacing={1}>
        {stack.name !== "reference" && (
          <FavoritesButton
            location={location}
            setLocation={setLocation}
            stacks={location.stacks.filter(
              (s) =>
                Boolean(s?.name) &&
                ["current", "reference"].indexOf(s.name) === -1
            )}
          />
        )}
        <SplitButton
          options={[
            {
              label: "Clear",
              disabled: false,
              onClick: () => {
                setStacks([]);
                const text = isMultiHangar
                  ? `This will remove all aircraft listed in the selected hangars.`
                  : `This will remove all aircraft listed in this ${location.type}.`;
                const onOk = isMultiHangar
                  ? (ids: string[]) => {
                      setHangars(
                        hangars.map((h) =>
                          ids.includes(h.id)
                            ? {
                                ...h,
                                stack: {
                                  ...h.stack,
                                  tenants: [],
                                  movableObstacles: [],
                                },
                              }
                            : h
                        )
                      );
                    }
                  : () => {
                      setStacks([]);
                      setStack({
                        ...stack,
                        tenants: [],
                        movableObstacles: [],
                      });
                    };
                setConfirm({
                  open: true,
                  text,
                  okText: "Yes, remove all aircraft",
                  onOk,
                });
              },
            },
            {
              label: "Dump",
              disabled: false,
              onClick: () => {
                const text = isMultiHangar
                  ? `This will dump all aircraft from the selected hangars.`
                  : `This will dump all aircraft to the front.`;
                const okText = isMultiHangar
                  ? "Yes, dump hangars"
                  : "Yes, dump hangar";
                const onOk = isMultiHangar
                  ? (ids: string[], dumpTo: string) => {
                      setStacks([]);
                      if (dumpTo === "HOLDING_AREA") {
                        const holdingAreaHangar = hangars.find(
                          (h) => h.id === HOLDING_AREA_ID
                        );
                        const tenantsForHoldingArea = hangars
                          .filter((h) => ids.includes(h.id))
                          .map((h) => h.stack.tenants)
                          .flat()
                          .map((t) => ({
                            ...t,
                            position: {
                              ...t.position,
                              stack_id: holdingAreaHangar.stack.id,
                              x: null,
                              y: null,
                              angle: null,
                            },
                          }));

                        setHangars(
                          hangars
                            .map((h) =>
                              ids.includes(h.id)
                                ? {
                                    ...h,
                                    stack: {
                                      ...h.stack,
                                      tenants: [],
                                    },
                                  }
                                : h
                            )
                            .map((h) =>
                              h.id === HOLDING_AREA_ID
                                ? {
                                    ...h,
                                    stack: {
                                      ...h.stack,
                                      tenants: [
                                        ...h.stack.tenants,
                                        ...tenantsForHoldingArea,
                                      ],
                                    },
                                  }
                                : h
                            )
                        );
                        return;
                      }
                      setHangars(
                        hangars.map((h) =>
                          ids.includes(h.id)
                            ? {
                                ...h,
                                stack: {
                                  ...h.stack,
                                  tenants: h.stack.tenants.map((t) => ({
                                    ...t,
                                    position: {
                                      ...t.position,
                                      x: null,
                                      y: null,
                                      angle: null,
                                    },
                                  })),
                                },
                              }
                            : h
                        )
                      );
                    }
                  : () => {
                      setStacks([]);
                      setStack({
                        ...stack,
                        tenants: stack.tenants.map((t) => ({
                          ...t,
                          position: {
                            ...t.position,
                            x: null,
                            y: null,
                            angle: null,
                          },
                        })),
                      });
                    };
                setConfirm({
                  open: true,
                  text,
                  okText,
                  onOk,
                  renderSecondaryAction: true,
                });
              },
            },
            {
              label: "All Tenants",
              disabled: stack.isReference,
              onClick: async () => {
                const text = isMultiHangar
                  ? "This will reset the selected hangars to the all tenant hangar layout."
                  : `This will reset to the all tenant ${location.type} layout.`;
                const onOk = isMultiHangar
                  ? (ids: string[]) => {
                      setStacks([]);
                      const resetHangarToReference = (h: Hangar): Hangar => {
                        const referenceStack = h.stacks.find(
                          (s) => s?.name === "reference"
                        );
                        return {
                          ...h,
                          stack: {
                            ...h.stack,
                            tenants: [...referenceStack.tenants].map((t) => ({
                              ...t,
                              position: {
                                ...t.position,
                                id: uuidv4(),
                                stack_id: h.stack.id,
                                entity_id: t.entity_id,
                              },
                            })),
                            movableObstacles: [
                              ...referenceStack.movableObstacles,
                            ].map((mo) => ({
                              ...mo,
                              position: {
                                ...mo.position,
                                id: uuidv4(),
                                stack_id: h.stack.id,
                                entity_id: mo.entity_id,
                              },
                            })),
                          },
                        };
                      };
                      setHangars(
                        hangars.map((h) =>
                          ids.includes(h.id) ? resetHangarToReference(h) : h
                        )
                      );
                    }
                  : () => {
                      setStacks([]);
                      const referenceStack = location.stacks.find(
                        (s) => s?.name === "reference"
                      );
                      setLocation({
                        ...location,
                        stack: {
                          ...location.stack,
                          tenants: [...referenceStack.tenants].map((t) => ({
                            ...t,
                            position: {
                              ...t.position,
                              id: uuidv4(),
                              stack_id: location.stack.id,
                              entity_id: t.entity_id,
                            },
                          })),
                          movableObstacles: [
                            ...referenceStack.movableObstacles,
                          ].map((mo) => ({
                            ...mo,
                            position: {
                              ...mo.position,
                              id: uuidv4(),
                              stack_id: location.stack.id,
                              entity_id: mo.entity_id,
                            },
                          })),
                        },
                      });
                    };

                setConfirm({
                  open: true,
                  text,
                  okText: "Yes, reset",
                  onOk,
                  renderSecondaryAction: false,
                });
              },
            },
          ]}
          startIcon={<CleaningServicesIcon />}
        />
        <Button
          variant="contained"
          color="info"
          startIcon={<ReplayIcon />}
          onClick={onClickUndo}
        >
          Undo
        </Button>
        <SplitButton
          startIcon={<PrintIcon />}
          options={[
            {
              label: "Print",
              disabled: false,
              onClick: () => {
                // if user is on firefox, tell them they can't do this
                if (navigator.userAgent.toLowerCase().includes("firefox")) {
                  setShowFireFoxError(true);
                  return;
                }
                if (isMultiHangar) {
                  setShowMultiHangarPrint(true);
                  return;
                }
                setPrint(true);
                setTimeout(() => {
                  handlePrint();
                  setPrint(false);
                }, 1000);
                mixpanel.track("Print", {
                  location_name: location.name,
                  location_type: location.type,
                });
              },
            },
            {
              label: "Copy",
              disabled: false,
              onClick: () => {
                if (navigator.userAgent.toLowerCase().includes("firefox")) {
                  setShowFireFoxError(true);
                  return;
                }
                if (isMultiHangar) {
                  setShowMultiHangarCopy(true);
                  return;
                }
                mixpanel.track("Copy", {
                  location_name: location.name,
                  location_type: location.type,
                });
                setCopy(true);
                setTimeout(async () => {
                  const canvas = await html2canvas(copyRef.current, {
                    useCORS: true,
                  });
                  const hangarURL = window.location.href;
                  // copy both the image and a link to the page to the clipboard
                  copyImageAndUrlToClipboard(canvas, hangarURL, location.type);
                  setCopy(false);
                  snackbar.notify({
                    text: "Copied to clipboard",
                    severity: "success",
                  });
                }, 1000);
              },
            },
          ]}
        />
      </Stack>
      {confirm?.open && !isMultiHangar && (
        <ConfirmationDialog
          title={"Please Confirm"}
          onCancel={() => setConfirm(null)}
          onClose={() => setConfirm(null)}
          {...confirm}
        />
      )}
      {print && (
        <Box
          sx={{
            position: "absolute",
            top: "100vh",
          }}
        >
          <div
            ref={componentRef}
            style={{
              display: "block",
              padding: 15,
              marginLeft: 50,
            }}
          >
            <Stack direction="column" justifyContent="space-between">
              <style type="text/css" media="print">
                {`@page { size: ${true ? "portrait" : "landscape"}; }`}
              </style>
              {renderOnPrint(idsToPrint)}
            </Stack>
          </div>
        </Box>
      )}
      <Dialog open={copy} fullScreen>
        <Loading />
      </Dialog>
      {copy && (
        <Box
          sx={{
            // move this thing to Siberia
            position: "absolute",
            top: "200vh",
            left: "200vw",
          }}
        >
          <div
            ref={copyRef}
            style={{
              position: "absolute",
              bottom: 0,
              right: "100%",
              display: "block",
              padding: 15,
              marginLeft: 50,
            }}
          >
            <Stack direction="column" justifyContent="space-between">
              {renderOnPrint(idsToPrint)}
            </Stack>
          </div>
        </Box>
      )}
      {showMultiHangarPrint && (
        <MultiHangarSelect
          title={"Print Hangars"}
          caption={"Select the hangars you would like to print."}
          okText={"Print"}
          hangars={hangars.filter((h) => hangarIds.indexOf(h.id) > -1)}
          onCancel={() => setShowMultiHangarPrint(false)}
          onOk={(idsToPrint) => {
            setPrint(true);
            setIdsToPrint(idsToPrint);
            setTimeout(() => {
              handlePrint();
              setPrint(false);
              setShowMultiHangarPrint(false);
            }, 1000);
            mixpanel.track("Print", {
              location_name: location.name,
              location_type: location.type,
            });
          }}
        />
      )}
      {showMultiHangarCopy && (
        <MultiHangarSelect
          title={"Copy Hangars"}
          caption={"Select the hangars you would like to copy."}
          okText={"Copy"}
          hangars={hangars.filter((h) => hangarIds.indexOf(h.id) > -1)}
          onCancel={() => setShowMultiHangarCopy(false)}
          onOk={(idsToPrint) => {
            setCopy(true);
            setIdsToPrint(idsToPrint);
            setTimeout(async () => {
              const canvas = await html2canvas(copyRef.current, {
                useCORS: true,
              });
              const hangarURL = window.location.href;
              // copy both the image and a link to the page to the clipboard
              copyImageAndUrlToClipboard(canvas, hangarURL, location.type);
              setCopy(false);
              snackbar.notify({
                text: "Copied to clipboard",
                severity: "success",
              });
              setShowMultiHangarCopy(false);
            }, 1000);
            mixpanel.track("Copy", {
              location_name: location.name,
              location_type: location.type,
            });
          }}
        />
      )}
      {confirm?.open && isMultiHangar && (
        <MultiHangarSelect
          title={"Please Confirm"}
          caption={confirm.text}
          okText={confirm.okText}
          hangars={hangars.filter((h) => hangarIds.indexOf(h.id) > -1)}
          onCancel={() => setConfirm(null)}
          renderSecondaryAction={confirm.renderSecondaryAction}
          onOk={(idsToPrint, dumpTo) => {
            confirm.onOk(idsToPrint, dumpTo);
            setConfirm(null);
          }}
        />
      )}
      {
        <Dialog open={fireFoxError} onClose={() => setShowFireFoxError(false)}>
          <DialogTitle>Feature Not Supported</DialogTitle>
          <DialogContent>
            <Typography>
              This feature is not supported on Firefox. If you wish to use this
              feature, please use a different browser.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowFireFoxError(false)}>OK</Button>
          </DialogActions>
        </Dialog>
      }
    </Stack>
  );
};

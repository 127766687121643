import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["rowsBeforePartialUpdates"];

/**
 * A helper function to check if the id provided is valid.
 * @param {GridRowId} id Id as [[GridRowId]].
 * @param {GridRowModel | Partial<GridRowModel>} row Row as [[GridRowModel]].
 * @param {string} detailErrorMessage A custom error message to display for invalid IDs
 */
export function checkGridRowIdIsValid(id, row, detailErrorMessage = 'A row was provided without id in the rows prop:') {
  if (id == null) {
    throw new Error(['MUI: The data grid component requires all rows to have a unique `id` property.', 'Alternatively, you can use the `getRowId` prop to specify a custom id for each row.', detailErrorMessage, JSON.stringify(row)].join('\n'));
  }
}
export const getRowIdFromRowModel = (rowModel, getRowId, detailErrorMessage) => {
  const id = getRowId ? getRowId(rowModel) : rowModel.id;
  checkGridRowIdIsValid(id, rowModel, detailErrorMessage);
  return id;
};
export const createRowsInternalCache = ({
  rows,
  getRowId,
  loading
}) => {
  const cache = {
    rowsBeforePartialUpdates: rows,
    loadingPropBeforePartialUpdates: loading,
    idRowsLookup: {},
    idToIdLookup: {},
    ids: []
  };

  for (let i = 0; i < rows.length; i += 1) {
    const row = rows[i];
    const id = getRowIdFromRowModel(row, getRowId);
    cache.idRowsLookup[id] = row;
    cache.idToIdLookup[id] = id;
    cache.ids.push(id);
  }

  return cache;
};
export const getRowsStateFromCache = ({
  apiRef,
  previousTree,
  rowCountProp,
  loadingProp
}) => {
  const _apiRef$current$unsta = apiRef.current.unstable_caches.rows,
        cacheForGrouping = _objectWithoutPropertiesLoose(_apiRef$current$unsta, _excluded);

  const rowCount = rowCountProp != null ? rowCountProp : 0;
  const groupingResponse = apiRef.current.unstable_applyStrategyProcessor('rowTreeCreation', _extends({}, cacheForGrouping, {
    previousTree
  }));
  const processedGroupingResponse = apiRef.current.unstable_applyPipeProcessors('hydrateRows', groupingResponse);
  const dataTopLevelRowCount = processedGroupingResponse.treeDepth === 1 ? processedGroupingResponse.ids.length : Object.values(processedGroupingResponse.tree).filter(node => node.parent == null).length;
  return _extends({}, processedGroupingResponse, {
    groupingResponseBeforeRowHydration: groupingResponse,
    loading: loadingProp,
    totalRowCount: Math.max(rowCount, processedGroupingResponse.ids.length),
    totalTopLevelRowCount: Math.max(rowCount, dataTopLevelRowCount)
  });
};
export const getTreeNodeDescendants = (tree, parentId, skipAutoGeneratedRows) => {
  var _tree$parentId;

  const children = (_tree$parentId = tree[parentId]) == null ? void 0 : _tree$parentId.children;

  if (children == null) {
    return [];
  }

  const validDescendants = [];

  for (let i = 0; i < children.length; i += 1) {
    const child = children[i];
    const childNode = tree[child];

    if (!skipAutoGeneratedRows || !childNode.isAutoGenerated) {
      validDescendants.push(child);
    }

    validDescendants.push(...getTreeNodeDescendants(tree, childNode.id, skipAutoGeneratedRows));
  }

  return validDescendants;
};
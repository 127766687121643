import { MultiPolygon, Point, Polygon } from "geojson";
import { PredefinedObstacle } from "./hooks/usePredefinedObstacle";
import { Aircraft } from "./screens/LabelingTool";

export type User = {
  id: string;
  email: string;
  _role: string;
  created_at: string;
};

export type FBOEmployee = {
  id: string;
  user_id: string;
  fbo_id: string;
  _role: string;
  user_display_name: string;
  created_at: string;
};

export type FBO = {
  id: string;
  name: string;
  subscription: string;
  user_role: string;
  sop_horizontal_spacing: number;
  sop_vertical_spacing: number;
  sop_overlap: boolean;
  sop_horizontal_spacing_tight: number;
  sop_vertical_spacing_tight: number;
  sop_overlap_tight: boolean;
  sop_horizontal_spacing_loose: number;
  sop_vertical_spacing_loose: number;
  sop_overlap_loose: boolean;
  sop_tug_type: string;
  sop_orientation_nose_in_threshold: number;
  sop_orientation_tail_in_threshold: number;
  nightly_base_rate: number;
  nightly_transient_rate: number;
  settings: FBOSettings;
  created_at: string;
};

export type FBOSettings = {
  displayEstimatedValueForManager: boolean;
  displayEstimatedValueForOperator: boolean;
  ignoreDeadSpaceForUtilization: boolean;
  hangarDisplayOrder: string[];
  rampDisplayOrder: string[];
};

export type Invitation = {
  id: string;
  claimed: boolean;
  email?: string;
  created_at: string;
};

export type Position = {
  id: string;
  stack_id: string;
  entity_id: string;
  x: number;
  y: number;
  angle: number;
  preferences: Preference[];
  /**
   * When this position object was set / last updated
   */
  timestamp?: number;
};

export type AutocompletePosition = {
  id: string;
  hangar_id: string;
  x: number;
  y: number;
  angle: number;
};

export enum Preference {
  PREFER_FRONT_ROW = "PREFER_FRONT_ROW",
  PREFER_BACK_ROW = "PREFER_BACK_ROW",
  PREFER_LEFT = "PREFER_LEFT",
  PREFER_RIGHT = "PREFER_RIGHT",
  PREFER_FRONT = "PREFER_FRONT",
  PREFER_BACK = "PREFER_BACK",
  // split hangar into thirds
  PREFER_LEFT_THIRD = "PREFER_LEFT_THIRD",
  PREFER_MIDDLE_THIRD = "PREFER_MIDDLE_THIRD",
  PREFER_RIGHT_THIRD = "PREFER_RIGHT_THIRD",
  NOSE_IN = "NOSE_IN",
  TAIL_IN = "TAIL_IN",
  // tail wheel is reversed b/c the image is upside down
  TAIL_WHEEL_NOSE_IN = "TAIL_WHEEL_TAIL_IN",
  TAIL_WHEEL_TAIL_IN = "TAIL_WHEEL_NOSE_IN",
  OK_TO_LEAVE_OUT = "OK_TO_LEAVE_OUT",
  LOCKED = "LOCKED",
}

export type Location<T> = T & {
  type: string;
};

export type Entity<T> = T & {
  entity_type: string;
};

export enum TenantType {
  BASE = "base",
  TRANSIENT = "transient",
}

export type Stack = {
  id: string;
  name?: string;
  location_id?: string;
  isReference: boolean;
  tenants: Tenant[];
  movableObstacles: MovableObstacle[];
  options: PlacementOptions;
};

export type Tenant = {
  id: string;
  fbo_id: string;
  entity_id: string;
  placement_id: string;
  aircraft_id: string;
  type: string;
  tail_number: string;
  owner_manager: string;
  aircraft: Aircraft;
  created_at?: string;
  selected: boolean;
  position: Position;
  suggestedPositions?: AutocompletePosition[];
  last_hangared?: string;
  note?: string;
  note_last_updated_by?: string;
  note_last_updated?: string;
};

export type MovableObstacle = {
  id: string;
  fbo_id: string;
  entity_id: string;
  selected: boolean;
  placement_id: string;
  obstacle_id: string;
  obstacle: PredefinedObstacle;
  position: Position;
};

export interface ParamSet {
  label?: string;
  run_id: string;
  aircrafts_to_place: AircraftToPlace[];
  locked_aircrafts: LockedAircraft[];
  obstacles: ObstacleSlim[];
  hangar: HangarSlim;
  options: PlacementOptions;
}

export type ILayout = {
  id: string;
  fbo_id: string;
  name: string;
  stack: Stack;
};

export type BenchmarkRate = {
  id: string;
  fbo_id: string;
  type: string;
  tenant_name: string;
  aircraft_id: string;
  aircraft: Aircraft;
  rate: number;
};

export interface ILocation {
  width: number;
  depth: number;
}

export type RefPoint = {
  id: string;
  point: Point;
  hangar_id: string;
};

export type HeatmapCell = {
  id: string;
  hangar_id: string;
  index_width: number;
  index_depth: number;
  ref_point_id: string;
  geom: Polygon;
  ref_point?: RefPoint;
};

export type HeatmapArea = {
  area: Polygon | MultiPolygon;
  reference_point: Point;
};

export type Hangar = {
  id: string;
  location_id: string;
  fbo_id: string;
  name: string;
  width: number;
  depth: number;
  height: number;
  left_door: number;
  right_door: number;
  wall_spacing_left: number;
  wall_spacing_back: number;
  wall_spacing_right: number;
  obstacles: Obstacle[];
  stack: Stack;
  stacks: Stack[];
  last_stacked: string;
  created_at: string;
  garageDoors: GarageDoor[];
  base_tenant_value?: number;
  sqftCost?: number;
  heatmap_cells?: HeatmapCell[];
};

export type Ramp = {
  id: string;
  location_id: string;
  fbo_id: string;
  name: string;
  width: number;
  depth: number;
  reference_image: string;
  outline: Polygon;
  markings: Marking[];
  stack: Stack;
  stacks: Stack[];
};

export type Marking = {
  id: string;
  ramp_id: string;
  type: MarkingType;
  geom: Polygon;
  selected?: boolean;
};

export enum MarkingType {
  OUTLINE = "Outline",
  REFERENCE_DISTANCE = "Reference Distance",
  RED_SOLID = "Red Solid",
  GREY = "Grey",
  WHITE_SOLID = "White Solid",
  WHITE_DASHED = "White Dashed",
  YELLOW_SOLID = "Yellow Solid",
  YELLOW_DASHED = "Yellow Dashed",
  YELLOW_SOLID_CURVED = "Yellow Solid Curved",
}

export type HangarSlim = {
  id: string;
  width: number;
  depth: number;
  left_door: number;
  right_door: number;
  garage_doors: GarageDoor[];
};

export interface ObstacleSlim {
  geom: Polygon;
  height: number;
}

export type AircraftToPlace = {
  id: string;
  placement_id: string;
  angles_to_try: number[];
  preferences: Preference[];
  sectors_to_try: number[];
};

export enum ObstacleType {
  OBSTRUCTION = "OBSTRUCTION",
  NO_TOW_AREA = "NO_TOW_AREA",
  UNOBSTRUCTIVE = "UNOBSTRUCTIVE",
  CAUTION = "CAUTION",
}

export type Obstacle = {
  id: string;
  fbo_id: string;
  hangar_id: string;
  x: number;
  y: number;
  width: number;
  depth: number;
  height: number;
  geom: Polygon;
  active?: boolean;
  type?: ObstacleType;
};

export type GarageDoor = {
  id: string;
  fbo_id: string;
  hangar_id: string;
  wall: string;
  x: number;
  y: number;
  width: number;
  active?: boolean;
};

export type LockedAircraft = {
  id: string;
  placement_id: string;
  x: number;
  y: number;
  angle: number;
};

export type PlacementOptions = {
  wall_spacing_left: number;
  wall_spacing_back: number;
  wall_spacing_right: number;
  horizontal_spacing: number;
  vertical_spacing: number;
  overlap: boolean;
  tug_spacing: number;
  density_threshold: number;
  error_type?: string;
  sample_size?: number;
  corner_strategy: CornerStrategy[];
  cloud_position_prioritization?: boolean;
  xy_weights: number[];
  towing_equipment_id: string;
  // heatmap: HeatmapArea[];
};

export enum CornerStrategy {
  ANGLE_SHALLOW = "S",
  ANGLE_45 = "45",
}

export const CornerStrategies = [
  [CornerStrategy.ANGLE_45, CornerStrategy.ANGLE_45],
  [CornerStrategy.ANGLE_45, CornerStrategy.ANGLE_SHALLOW],
  [CornerStrategy.ANGLE_SHALLOW, CornerStrategy.ANGLE_45],
  [CornerStrategy.ANGLE_SHALLOW, CornerStrategy.ANGLE_SHALLOW],
];

export type Bounds = [geometric.Point, geometric.Point];

export type EntityPolygon = {
  entity_id: string;
  polygon: Polygon;
  bounds: Bounds;
  hash: string;
};

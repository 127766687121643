import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useLocalStorage } from "react-use";

type Announcement = {
  id: string;
  open: boolean;
};

const AnnouncementContent = () => (
  <Stack direction="column" spacing={1}>
    <Divider />
    <Typography>
      We're excited to bring you Notes, our latest feature that lets you add
      custom notes to individual aircraft, making team coordination and stack
      planning easier than ever. Watch the video below to learn more!
    </Typography>
    <iframe
      width="100%"
      height="400"
      src="https://www.youtube.com/embed/fC2AelLU-y8?si=F9d8SErV9XKjk3Rp"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>
  </Stack>
);

export const AnnouncementContainer: React.FC<{
  children?: React.ReactNode;
}> = ({ children, ...props }) => {
  const [announcement, setAnnouncement] = useLocalStorage<Announcement>(
    "airplx/announcement/notes",
    {
      id: "feature/notes",
      open: true,
    },
    {
      raw: false,
      serializer: (value: Announcement) => {
        return JSON.stringify(value);
      },
      deserializer: (value: string) => {
        return JSON.parse(value) as Announcement;
      },
    }
  );

  return (
    <>
      {children}
      <Dialog
        open={announcement.open}
        onClose={() => setAnnouncement({ ...announcement, open: false })}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{`New Feature: Notes`}</DialogTitle>
        <DialogContent>
          <AnnouncementContent />
        </DialogContent>
        <DialogActions>
          <Typography variant="caption">
            <Button
              variant="contained"
              onClick={() => {
                setAnnouncement({ ...announcement, open: false });
                console.log("help");
              }}
            >
              Cool, let's go!
            </Button>
          </Typography>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { Popover } from "@mui/material";
import * as React from "react";
import { useApi } from "../../containers/ApiContainer";
import { Preferences } from "../../hooks/useUserPreferences";
import { DraggableAircraft } from "../../screens/Hangar/HangarLayout/DraggableAircraftNew";
import { calculateAircraftImageDimensions } from "../../screens/Hangar/HangarLayout/DraggableAircraftNew/Scale";
import {
  getSpacingBufferDimensions,
  truncateAt15Chars,
} from "../../screens/Hangar/HangarLayout/HangarLayout";
import { Position, Stack, Tenant } from "../../types";
import { AircraftPreferencesContextMenu } from "../CurrentAircraft/AircraftPreferencesListItemMenu";
import { RightClick } from "./Layout";

type Props = {
  layoutHeight: number;
  feetToPixels: number;
  tenant: Tenant;
  stack: Stack;
  setStack: (stack: Stack) => void;
  preferences: Preferences;
  onPositionChange: (placement_id: string, position: Position) => void;
  onChange?: (placement_id: string, position: Position) => void;
  rightClick: RightClick;
  setRightClick: (rightClick: RightClick) => void;
  spacingBufferOverlayElement: React.ReactNode;
  readOnly?: boolean;
  hotkey?: string;
};

export const FullyLoadedDraggableAircraft: React.FC<Props> = ({
  tenant,
  feetToPixels,
  stack,
  setStack,
  preferences,
  layoutHeight,
  onPositionChange,
  onChange = () => {},
  rightClick,
  setRightClick,
  spacingBufferOverlayElement,
  readOnly = false,
  hotkey,
}) => {
  const { postgrest } = useApi();
  const imageDimensions = {
    ...calculateAircraftImageDimensions(feetToPixels, tenant.aircraft),
  };

  const position = {
    x: tenant.position.x * feetToPixels,
    y: tenant.position.y * feetToPixels,
    angle: tenant.position.angle,
    timestamp: tenant.position.timestamp,
  };

  // This assumes the y position is an alignment to the bottom of the
  // hangar and this reverses it to align to the top to pass on to
  // the DraggableAircraftNew
  position.y = layoutHeight - (position.y + imageDimensions.height);

  let label = "";

  if (
    !preferences.forceDisplayTailNumber &&
    !preferences.forceDisplayModel &&
    preferences.notes
  ) {
    if (tenant.selected) {
      label = tenant.tail_number || "N/A";
    } else {
      label = ``;
    }
  } else if (
    preferences.forceDisplayTailNumber &&
    preferences.forceDisplayModel
  ) {
    label = `${tenant.tail_number || "N/A"}\n${truncateAt15Chars(
      tenant.aircraft.model
    )}`;
  } else if (preferences.forceDisplayTailNumber) {
    label = `${tenant.tail_number || "N/A"}`;
  } else if (preferences.forceDisplayModel) {
    label = `${truncateAt15Chars(tenant.aircraft.model)}`;
  } else {
    label = `${tenant.tail_number || "N/A"}`;
  }

  return (
    <>
      <DraggableAircraft
        tenantType={tenant.type}
        entity_id={tenant.entity_id}
        readOnly={readOnly}
        label={label}
        hotkey={hotkey}
        note={tenant.note}
        labelWidth={preferences.forceDisplayModel ? 105 : null}
        forceDisplayLabel={
          preferences.forceDisplayTailNumber ||
          preferences.forceDisplayModel ||
          Boolean(hotkey) ||
          (preferences.notes && tenant.note)
        }
        spacingBufferOverlayElement={
          !readOnly && preferences.showSOPSpacing
            ? spacingBufferOverlayElement
            : null
        }
        aircraft={{
          ...tenant.aircraft,
          selected: tenant.selected,
          tail_number: tenant.tail_number,
          position: position,
          ...getSpacingBufferDimensions(tenant.aircraft, {
            feetToPixels,
          }),
        }}
        imageDimensions={imageDimensions}
        onPositionChange={(position) => {
          // Adjust the position to be aligned to the bottom
          // as it will come from the component aligned to the top

          // Switch the y position to align to the bottom of the hangar
          position.y = layoutHeight - (position.y + imageDimensions.height);
          position = {
            ...position,
            x: position.x / feetToPixels,
            y: position.y / feetToPixels,
          };
          onPositionChange(tenant.entity_id, position);
        }}
        onChange={(position) => {
          // Adjust the position to be aligned to the bottom
          // as it will come from the component aligned to the top

          // Switch the y position to align to the bottom of the hangar
          position.y = layoutHeight - (position.y + imageDimensions.height);
          position = {
            ...position,
            x: position.x / feetToPixels,
            y: position.y / feetToPixels,
          };
          onChange(tenant.entity_id, position);
        }}
        onPlaneClick={() => {
          // moved to the global onclick event
        }}
        onPlaneRightClick={({ type, event }) => {
          // moved to the global onclick event
        }}
        onStartTowing={() => {
          // we want to make sure the aircraft is selected
          setStack({
            ...stack,
            tenants: stack.tenants.map((t) => ({
              ...t,
              selected: t.placement_id === tenant.placement_id ? true : false,
            })),
          });
        }}
        feetToPixels={feetToPixels}
      />
      <Popover
        id={`popover-right-click-${tenant.id}`}
        open={rightClick?.entity_id === tenant.entity_id}
        onClose={() => setRightClick(null)}
        anchorReference="anchorPosition"
        anchorPosition={
          rightClick !== null
            ? {
                top: rightClick.mouseY as number,
                left: rightClick.mouseX as number,
              }
            : undefined
        }
      >
        <AircraftPreferencesContextMenu
          componentType="menulist"
          tenant={tenant}
          setTenant={(newTenant) => {
            setStack({
              ...stack,
              tenants: stack.tenants.map((t) =>
                t.placement_id == tenant.placement_id ? newTenant : t
              ),
            });
          }}
          preferences={tenant.position.preferences ?? []}
          setPreferences={(preferences) => {
            setStack({
              ...stack,
              tenants: stack.tenants.map((t) => ({
                ...t,
                position: {
                  ...t.position,
                  preferences:
                    t.placement_id === tenant.placement_id
                      ? preferences
                      : t.position.preferences,
                },
              })),
            });
          }}
          onDeleteAircraft={async () => {
            setStack({
              ...stack,
              tenants: stack.tenants.filter(
                (t) => t.placement_id !== tenant.placement_id
              ),
            });
            // if the stack is a reference, then we also need to convert the tenant to a transient
            if (stack.name === "reference") {
              await postgrest
                .from("tenant")
                .update({
                  type: "transient",
                })
                .eq("id", tenant.id);
            }
            setRightClick(null);
          }}
          menuProps={{
            open: true,
          }}
          stack={stack}
          setStack={(updatedStack) => {
            setStack({
              ...stack,
              ...updatedStack,
            });
          }}
        />
      </Popover>
    </>
  );
};

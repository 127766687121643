import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  ButtonGroup,
  Divider,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";

type Props = {
  currentZoom: number;
  onClickPlus: () => void;
  onClickMinus: () => void;
};

export const ZoomControl: React.FC<Props> = ({ onClickPlus, onClickMinus }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.mobileStepper - 1,
        backgroundColor: "white",
      }}
    >
      <ButtonGroup orientation="vertical" variant="contained">
        <Tooltip title="Zoom In" placement="left">
          <IconButton
            onClick={onClickPlus}
            sx={{
              borderRadius: 0,
              color: "white",
              backgroundColor: "success.main",
            }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Divider />
        <Tooltip title="Zoom Out" placement="left">
          <IconButton
            onClick={onClickMinus}
            sx={{
              borderRadius: 0,
              color: "white",
              backgroundColor: "success.main",
            }}
          >
            <RemoveIcon />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    </Box>
  );
};

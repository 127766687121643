import { Box } from "@mui/material";
import * as React from "react";
import { useMultiHangarState } from "../../containers/MultiHangarContainer";
import { useRampState } from "../../containers/RampStateContainer";
import { useThisHangar } from "../../hooks/useThisHangar";
import { Preferences } from "../../hooks/useUserPreferences";
import { RampCanvasReadOnly } from "../../screens/Settings/RampsSettings/RampEditor/RampCanvas";
import { Hangar, Location, Ramp } from "../../types";
import { HangarLayoutBackground } from "./HangarLayoutBackground";

type Props = {
  selectable: boolean;
  location: Location<Hangar | Ramp>;
  preferences: Preferences;
  width: number;
  height: number;
  feetToPixels: number;
  backgroundColor: string;
  dashColor: string;
  borderColor?: string;
  onClick: (evt: React.MouseEvent<HTMLElement>) => void;
  onMouseDown: (evt: React.MouseEvent<HTMLElement>) => void;
  onMouseUp: (evt: React.MouseEvent<HTMLElement>) => void;
  onMouseMove: (evt: React.MouseEvent<HTMLElement>) => void;
  onMouseEnter: (evt: React.MouseEvent<HTMLElement>) => void;
  onContextMenu: (evt: React.MouseEvent<HTMLElement>) => void;
  children?: React.ReactNode;
};

export const LayoutBackground = React.forwardRef((props: Props, ref) => {
  const {
    selectable,
    location,
    preferences,
    width,
    height,
    feetToPixels,
    onClick,
    onMouseDown,
    onMouseUp,
    onMouseMove,
    onMouseEnter,
    onContextMenu,
    backgroundColor,
    dashColor,
    borderColor,
    ...otherProps
  } = props;
  // TODO: not sure wtf is going on here...
  const { getHangar, isMultiHangar } = useMultiHangarState();
  const { hangar: hangarX } = useThisHangar();
  const hangar = isMultiHangar ? getHangar(location.id) : hangarX;
  if (hangar) {
    return (
      <HangarLayoutBackground
        ref={ref}
        hangar={hangar}
        selectable={false}
        width={width}
        height={height}
        feetToPixels={feetToPixels}
        backgroundColor={backgroundColor}
        dashColor={dashColor}
        borderColor={borderColor}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseEnter={onMouseEnter}
        onContextMenu={onContextMenu}
      >
        {props.children}
      </HangarLayoutBackground>
    );
  }

  const { ramp } = useRampState();
  const showReferenceImage = preferences?.showRampReferenceImage ?? false;
  const rampBackgroundColor = showReferenceImage
    ? `${props.backgroundColor}80`
    : props.backgroundColor;

  return (
    <Box
      ref={ref}
      onClick={onClick}
      onContextMenu={onContextMenu}
      sx={{
        m: 2,
        position: "relative",
        width,
        // height,
        height: "100%",
        // TODO: i'm not sure why this was here, but it was causing the planes at the bottom of the ramp to be cut off
        // overflow: "hidden",
        "&:hover": selectable
          ? {
              border: "2px solid black",
              borderColor: "success.main",
              cursor: "pointer",
            }
          : {},
      }}
    >
      {props.children}
      <RampCanvasReadOnly
        ramp={ramp}
        width={width}
        backgroundColor={rampBackgroundColor}
        showReferenceImage={showReferenceImage}
      />
    </Box>
  );
});

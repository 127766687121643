import Draggable from "react-draggable";
import { Box, Menu, MenuItem } from "@mui/material";
import { GarageDoor, Hangar } from "../../../types";
import React = require("react");

type DraggableObstacleContextMenuProps = {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  onDelete: () => void;
};

export const DraggableGarageDoorContextMenu: React.FC<DraggableObstacleContextMenuProps> = ({
  anchorEl,
  onDelete,
  onClose,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={onClose}
    >
      <MenuItem onClick={onDelete}>Delete</MenuItem>
    </Menu>
  );
};

type Props = {
  hangar: Hangar;
  feetToPixels: number;
  garageDoor: GarageDoor;
  onDrag: (obstacle: GarageDoor) => void;
  onDelete: (obstacle: GarageDoor) => void;
};

export const DraggableGarageDoor: React.FC<Props> = ({
  hangar,
  feetToPixels,
  garageDoor,
  onDrag,
  onDelete,
}) => {
  const PX_WIDTH = 2;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const x =
    garageDoor.wall === "back"
      ? feetToPixels * garageDoor.x
      : garageDoor.wall === "right"
      ? feetToPixels * (hangar.width - PX_WIDTH)
      : 0;

  const y = garageDoor.wall === "back" ? 0 : feetToPixels * garageDoor.y;

  const sx = {
    backgroundColor: "#FFAC1C",
    border: "#FFAC1C",
  };
  const sxHover = {
    backgroundColor: "#f59e07",
    border: "#f59e07",
  };

  if (!feetToPixels) {
    return null;
  }

  return (
    <>
      <Draggable
        bounds="parent"
        axis={garageDoor.wall === "back" ? "x" : "y"}
        defaultPosition={{ x, y }}
        onDrag={(evt, data) => {
          let x, y;
          if (garageDoor.wall === "back") {
            x = data.x / feetToPixels;
            y = hangar.depth;
          } else if (garageDoor.wall === "right") {
            x = hangar.width;
            y = data.y / feetToPixels;
          } else if (garageDoor.wall === "left") {
            x = 0;
            y = data.y / feetToPixels;
          }
          const newGarageDoor = {
            ...garageDoor,
            x,
            y,
          };

          onDrag({
            ...newGarageDoor,
            active: true,
          });
        }}
        onStop={(evt, data) => {
          onDrag({
            ...garageDoor,
            active: false,
          });
        }}
      >
        <Box
          onContextMenu={(evt) => {
            evt.preventDefault();
            setAnchorEl(evt.currentTarget);
          }}
          sx={{
            ...sx,
            position: "absolute",
            cursor: "grab",
            width:
              garageDoor.wall === "back"
                ? garageDoor.width * feetToPixels
                : PX_WIDTH * feetToPixels,
            height:
              garageDoor.wall === "back"
                ? PX_WIDTH * feetToPixels
                : garageDoor.width * feetToPixels,
            "&:hover": {
              ...sxHover,
            },
          }}
        />
      </Draggable>
      <DraggableGarageDoorContextMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onDelete={() => onDelete(garageDoor)}
      />
    </>
  );
};

import { AlertColor } from "@mui/material";
import * as React from "react";
import { uuidv4 } from "../utils";
import { createContainer } from "./StateContainer";

type Notification = {
  id?: string;
  text: string;
  open?: boolean;
  severity?: AlertColor;
};

type Props = {};
const useSnackbarContainerState = (props: Props) => {
  const [notifications, setNotifications] = React.useState<Notification[]>([]);
  return {
    notify: (notification: Notification) => {
      setNotifications((notifications) => [
        ...notifications,
        {
          ...notification,
          id: uuidv4(),
          severity: notification.severity ?? "error",
          open: true,
        },
      ]);
    },
    notifications,
    setNotifications,
  };
};
const SnackbarContainerState = createContainer(useSnackbarContainerState);

export const SnackbarContainer: React.FC<{ children?: React.ReactNode }> = ({
  children,
  ...props
}) => {
  const snackbar = SnackbarContainerState.useContainer();
  return (
    <SnackbarContainerState.Provider initialState={{ ...snackbar, ...props }}>
      {children}
    </SnackbarContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useSnackbar = SnackbarContainerState.useContainer;

import HelpIcon from "@mui/icons-material/Help";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useIdentity } from "../../containers/IdentityContainer";
import { useSnackbar } from "../../containers/SnackbarContainer";
import { useTokenMaker } from "../../hooks";

export const SupportButton: React.FC = () => {
  const { url: tokenMakerURL } = useTokenMaker();
  const { activeFBO } = useActiveFBOs();
  const snackbar = useSnackbar();
  const { airplxIdentity } = useIdentity();
  const [open, setOpen] = React.useState<boolean>(false);
  const [content, setContent] = React.useState("");
  React.useEffect(() => {
    setContent("");
  }, [open]);

  const onSubmit = React.useCallback(async () => {
    const info = [
      ...Object.entries(airplxIdentity).map((entry) => ({
        field: `User ${entry[0]}`,
        value: entry[1],
      })),
      ...Object.entries(activeFBO).map((entry) => ({
        field: `FBO ${entry[0]}`,
        value: entry[1],
      })),
      {
        field: "Application page",
        value: window.location.href,
      },
      {
        field: "Application submit time",
        value: new Date(),
      },
      {
        field: "Application resolution",
        value: `${window.innerWidth}px x ${window.innerHeight}px`,
      },
    ];

    const payload = {
      content,
      info,
    };
    await fetch(`${tokenMakerURL}/support`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    snackbar.notify({
      text: `Thank you! Your support request has been recieved. We will reach out to you shortly.`,
      severity: "success",
    });

    setOpen(false);
  }, [content]);
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          right: -60,
          top: "40%",
          transform: `rotate(90deg)`,
          zIndex: 100000,
        }}
      >
        <Button
          color="success"
          variant="contained"
          size="small"
          sx={{ borderRadius: 0 }}
          onClick={() => setOpen(true)}
          startIcon={<HelpCenterIcon />}
        >
          Contact Support
        </Button>
      </Box>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="inherit">Support Inquiry</Typography>
            <Button
              startIcon={<HelpIcon />}
              LinkComponent={Link}
              target="_blank"
              href="https://help.airplx.com/docs/Hangars%20and%20Ramps/Current%20Hangar"
            >
              Tutorial Videos
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box p={1}>
            <TextField
              fullWidth
              label="Question"
              placeholder="Your support inquiry goes here..."
              multiline
              minRows={10}
              onChange={(evt) => setContent(evt.target.value)}
              value={content}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { GridColDef } from "@mui/x-data-grid";
import * as React from "react";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useHangarsState } from "../../containers/HangarsStateContainer";
import { useLocationsState } from "../../containers/LocationsStateContainer";
import { useRampsState } from "../../containers/RampsStateContainer";
import { Tenant } from "../../types";
import { lastHangaredText } from "../../utils/phrasing";
import { ConfirmationDialog } from "../../widgets/ConfirmationDialog";
import { EditTenant } from "../../widgets/EditTenant";
import { AddTenantButton } from "../Settings/HangarSettings/AddTenantButton";
import { TenantGrid } from "./TenantGrid";

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ButtonNotes } from "../../widgets/CurrentAircraft/ButtonNotes";

type Props = {
  filter: string;
  tenants: Tenant[];
  setTenant: (tenant: Tenant) => void;
  deleteTenant: (tenant: Tenant) => void;
  setFilter: (text: string) => void;
  editTenantId: string;
  setEditTenantId: (x: string) => void;
};

export const TenantsPresenter: React.FC<Props> = ({
  filter = "",
  tenants,
  setTenant,
  setFilter,
  deleteTenant,
  editTenantId,
  setEditTenantId,
  ...props
}) => {
  const { activeFBO } = useActiveFBOs();
  const { ramps } = useRampsState();
  const { hangars } = useHangarsState();
  const {
    sendToLocation,
    locationOverides,
    findTenantLocation,
  } = useLocationsState();
  const [tenantToDelete, setTenantToDelete] = React.useState<Tenant>();
  const [
    limitToAssignedLocationId,
    setLimitToAssignedLocationId,
  ] = React.useState<string>("");
  const [
    limitToCurrentLocationId,
    setLimitToCurrentLocationId,
  ] = React.useState<string>("");

  const tenantsToDisplay = React.useMemo(() => {
    return tenants.filter((t) => {
      if (
        !Boolean(filter) &&
        !Boolean(limitToAssignedLocationId) &&
        !Boolean(limitToCurrentLocationId)
      ) {
        return true;
      }

      const currentLocation = findTenantLocation(false, t);
      const referenceLocation = findTenantLocation(true, t);
      const currentLocationName = currentLocation?.name ?? "";
      const referenceLocationName = referenceLocation?.name ?? "";

      const queryString = `${t.type} - ${t.tail_number} - ${t.owner_manager} - ${t.aircraft.make} - ${t.aircraft.model} - ${t.note} - ${currentLocationName} - ${referenceLocationName}`.toLocaleLowerCase();

      const matchesQuerystring = Boolean(filter)
        ? queryString.indexOf(filter.toLocaleLowerCase()) > -1
        : true;

      if (limitToAssignedLocationId) {
        if (limitToAssignedLocationId === "ALL" && t.type === "base") {
          return matchesQuerystring;
        }
        if (
          limitToAssignedLocationId === "Unassigned" &&
          !referenceLocation &&
          t.type === "base"
        ) {
          return matchesQuerystring;
        }
        if (referenceLocation?.location_id === limitToAssignedLocationId) {
          return matchesQuerystring;
        }
        return false;
      }

      if (limitToCurrentLocationId) {
        if (limitToCurrentLocationId === "ALL") {
          return matchesQuerystring;
        }
        if (currentLocation?.location_id === limitToCurrentLocationId) {
          return matchesQuerystring;
        }
        return false;
      }

      return matchesQuerystring;
    });
  }, [
    tenants,
    filter,
    limitToAssignedLocationId,
    limitToCurrentLocationId,
    locationOverides,
  ]);

  const additionalColumns: GridColDef[] = [
    {
      field: "last_hangared",
      headerName: "Last Hangared",
      width: 175,
      editable: false,
      renderCell: (params) => {
        return lastHangaredText(
          params.value,
          Boolean(findTenantLocation(false, params.row))
        );
      },
    },
    {
      field: "note",
      headerName: "Note",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.note}>
            <Typography noWrap>{params.row.note}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      width: 250,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <ButtonNotes
              variant="contained"
              tenant={tenants.find((t) => t.id === params.row.id)}
              setTenant={(tenant: Tenant) => {
                setTenant(tenant);
              }}
            />
            <Button
              size="small"
              variant="contained"
              color="inherit"
              onClick={() => setEditTenantId(params.row.id)}
            >
              Edit
            </Button>
            <IconButton
              data-testid="delete"
              onClick={() => setTenantToDelete(params.row)}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const selectedTenant = tenants.find((tenant) => tenant.id === editTenantId);

  return (
    <Box sx={{ ml: 2, mr: 2, height: "100%" }}>
      <Stack direction="column" spacing={2} sx={{ height: "100%" }}>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
          <Typography variant="h6">
            Transient & Tenant Aircraft – {activeFBO?.name}
          </Typography>
          <Stack direction="row" spacing={1}>
            <AddTenantButton
              location={null}
              sendToLocation={sendToLocation}
              onAdd={(tenant: Tenant) => setTenant(tenant)}
              stack={null}
              setStack={() => null}
              hideAddMovableObstacle={true}
            />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1}>
          <TextField
            fullWidth
            placeholder="Search by Tail #, Owner, or Model"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(evt) => setFilter(evt.target.value)}
            inputProps={{ testid: "search" }}
            value={filter}
          />

          <Stack direction="row" spacing={1} justifyContent="flex-start">
            <Stack direction="row" alignItems="center">
              <TextField
                select
                fullWidth
                label="Show Tenants"
                value={limitToAssignedLocationId}
                onChange={(evt) => {
                  setLimitToCurrentLocationId("");
                  setLimitToAssignedLocationId(evt.target.value);
                }}
                sx={{ minWidth: 240 }}
              >
                <MenuItem value="ALL">Show All Tenants</MenuItem>
                <MenuItem value="Unassigned">Show Unassigned</MenuItem>
                {[...hangars, ...ramps].map((location) => (
                  <MenuItem
                    key={`location-tenant-menu-${location.location_id}`}
                    value={location.location_id}
                  >
                    Assigned to {location.name}
                  </MenuItem>
                ))}
              </TextField>
              <IconButton onClick={() => setLimitToAssignedLocationId("")}>
                <ClearIcon />
              </IconButton>
            </Stack>
            <Stack direction="row" alignItems="center">
              <TextField
                select
                fullWidth
                label="Show by Current Location"
                value={limitToCurrentLocationId}
                onChange={(evt) => {
                  setLimitToAssignedLocationId("");
                  setLimitToCurrentLocationId(evt.target.value);
                }}
                sx={{ minWidth: 240 }}
              >
                {[...hangars, ...ramps].map((location) => (
                  <MenuItem
                    key={`location-transient-menu-${location.location_id}`}
                    value={location.location_id}
                  >
                    {location.name}
                  </MenuItem>
                ))}
              </TextField>
              <IconButton onClick={() => setLimitToCurrentLocationId("")}>
                <ClearIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>

        <TenantGrid
          tenants={tenantsToDisplay}
          additionalColumns={additionalColumns}
        />
      </Stack>
      {Boolean(editTenantId) && (
        <EditTenant
          displayTypeAndLocation
          stack={null}
          setStack={null}
          tenant={selectedTenant}
          setTenant={setTenant}
          onClose={() => setEditTenantId(null)}
        />
      )}
      {Boolean(tenantToDelete) && (
        <ConfirmationDialog
          title={`Are you sure you want to delete ${tenantToDelete.tail_number ||
            tenantToDelete.aircraft.model}?`}
          text={"This aircraft will be permanently deleted."}
          okText="Yes"
          onCancel={() => {
            setTenantToDelete(null);
          }}
          onOk={() => {
            deleteTenant(tenantToDelete);
            setTenantToDelete(null);
          }}
          onClose={() => {
            setTenantToDelete(null);
          }}
        />
      )}
    </Box>
  );
};

import * as React from "react";
import { RampEditorPresenter } from "./RampEditorPresenter";
import { RampStateContainer } from "../../../../containers/RampStateContainer";

type Props = {
  ramp_id: string;
  onClose: () => void;
};

export const RampEditor: React.FC<Props> = ({ ramp_id, ...props }) => {
  return (
    <RampStateContainer
      initialState={{
        ramp_id,
        isReference: false,
        includeReferenceImage: true,
      }}
    >
      <RampEditorPresenter {...props} />
    </RampStateContainer>
  );
};

import * as React from "react";
import * as ReactGA from "react-ga";
import { HashRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import {
  ActiveFBOContainer,
  useActiveFBOs,
} from "./containers/ActiveFBOContainer";
import { AnnouncementContainer } from "./containers/AnnouncementContainer";
import { ApiContainer } from "./containers/ApiContainer";
import { DraggableAircraftContainer } from "./containers/DraggableAircraftContainer";
import { EventContainerProvider } from "./containers/EventContainer";
import {
  FeatureFlagsContainer,
  useFeatureFlags,
} from "./containers/FeatureFlagsContainer";
import { IdentityContainer, useIdentity } from "./containers/IdentityContainer";
import { LoadingProgressContainer } from "./containers/LoadingProgressContainer";
import { SchemaContainer } from "./containers/SchemaContainer";
import { SnackbarContainer } from "./containers/SnackbarContainer";
import { ThemeContainer } from "./containers/ThemeContainer";
import { UserPreferencesContainer } from "./containers/UserPreferencesContainer";
import { ClaimInvite } from "./screens/ClaimInvite";
import { ConvertSVG } from "./screens/ConvertSVG";
import { Estimator } from "./screens/Estimator";
import { FlaggedStacks } from "./screens/FlaggedStacks";
import { Hangar } from "./screens/Hangar";
import { HangarAlgorithmDev } from "./screens/HangarAlgorithmDev";
import { Hangars } from "./screens/Hangars";
import { LabelingTool } from "./screens/LabelingTool";
import { Login } from "./screens/Login";
import { Maintenance } from "./screens/Maintenance";
import { MultiHangar } from "./screens/MultiHangar";
import { NoActiveFBO } from "./screens/NoActiveFBO";
import { Ramp } from "./screens/Ramp";
import { Ramps } from "./screens/Ramps";
import { Settings } from "./screens/Settings";
import { StackMaster } from "./screens/StackMaster";
import { SuperAdmin } from "./screens/SuperAdmin";
import { Tenants } from "./screens/TransientsAndTenants";
import { LoadingProgress } from "./widgets/LoadingProgress";
import { Nav } from "./widgets/Nav";
import { Snackbar } from "./widgets/Snackbar";
import { SupportButton } from "./widgets/SupportButton/SupportButton";

const TRACKING_ID = "G-80LQDPGBHS";
ReactGA.initialize(TRACKING_ID);

type Props = {
  children?: React.ReactNode;
};

const ProtectedRoute: React.FC<{ mustBelongtoFBO?: boolean }> = ({
  mustBelongtoFBO = false,
  ...props
}) => {
  const { isLoggedIn } = useIdentity();
  const { ready, activeFBO } = useActiveFBOs();

  if (!isLoggedIn) {
    return (
      <Navigate to={"/login"} replace state={{ from: window.location.href }} />
    );
  }

  if (mustBelongtoFBO) {
    // async so need to wait until it's pulled data
    if (!ready) {
      return null;
    }
    if (!activeFBO) {
      return <NoActiveFBO />;
    }
  }

  return <Outlet />;
};

export const MainContainer: React.FC<Props> = ({ children, ...props }) => {
  return (
    <FeatureFlagsContainer>
      <EventContainerProvider>
        <ThemeContainer>
          <SnackbarContainer>
            <LoadingProgressContainer>
              <IdentityContainer {...props}>
                <ApiContainer>
                  <SchemaContainer>
                    <UserPreferencesContainer>
                      <ActiveFBOContainer>
                        <DraggableAircraftContainer>
                          <AnnouncementContainer>
                            {children}
                          </AnnouncementContainer>
                        </DraggableAircraftContainer>
                      </ActiveFBOContainer>
                    </UserPreferencesContainer>
                  </SchemaContainer>
                </ApiContainer>
              </IdentityContainer>
            </LoadingProgressContainer>
          </SnackbarContainer>
        </ThemeContainer>
      </EventContainerProvider>
    </FeatureFlagsContainer>
  );
};

export const Main: React.FC<Props> = ({ children, ...props }) => {
  return (
    <MainContainer>
      <App {...props}></App>
    </MainContainer>
  );
};

export const App: React.FC<Props> = ({ children, ...props }) => {
  if (Boolean(useFeatureFlags().isMaintenance)) {
    return <Maintenance />;
  }

  return (
    <HashRouter>
      <Nav>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* needs to be logged in, but doesn't need to be in an FBO yet */}
          <Route element={<ProtectedRoute />}>
            <Route path="/claim-invite/:inviteId" element={<ClaimInvite />} />
          </Route>
          <Route element={<ProtectedRoute mustBelongtoFBO />}>
            <Route path="/" element={<Navigate to="/hangars" replace />} />
            <Route path="/hangars" element={<Hangars />} />
            <Route path="/hangars/:id" element={<Hangar />} />
            <Route path="/multi-hangar/:ids" element={<MultiHangar />} />
            <Route
              path="/reference-hangars/:id"
              element={<Hangar isReference={true} />}
            />
            <Route
              path="/algorithm-evaluation/:id"
              element={<HangarAlgorithmDev isReference={false} />}
            />
            <Route path="/tenants" element={<Tenants />} />
            {/* real app */}
            <Route path="/ramps" element={<Ramps />} />
            <Route path="/ramps/:id" element={<Ramp />} />
            <Route
              path="/reference-ramps/:id"
              element={<Ramp isReference={true} />}
            />
            <Route path="/fee-calculator" element={<Estimator />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/flagged-stacks" element={<FlaggedStacks />} />
            <Route path="/stackmaster" element={<StackMaster />} />
            <Route path="/stackmaster/:id" element={<StackMaster />} />
          </Route>
          {/* internal-only pages */}
          <Route element={<ProtectedRoute />}>
            <Route path="/labeling-tool" element={<LabelingTool />} />
            <Route
              path="/labeling-tool/:aircraftId"
              element={<LabelingTool />}
            />
            <Route path="/convert-svg" element={<ConvertSVG />} />
            <Route path="/super-admin" element={<SuperAdmin />} />
          </Route>
        </Routes>
        <Snackbar autohideDuration={3000} />
        <LoadingProgress />
      </Nav>
      <SupportButton />
    </HashRouter>
  );
};

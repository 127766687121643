import { Box, Stack, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { usePrevious } from "react-use";
import { useLocationsState } from "../../containers/LocationsStateContainer";
import { useMultiHangarState } from "../../containers/MultiHangarContainer";
import { useTowingEquipmentState } from "../../containers/TowingEquipmentContainer";
import { Stack as StackType, Tenant } from "../../types";
import { Layout } from "../../widgets/Layout";
import { CustomPlacementOptions } from "../Hangar/CustomStackDialog";
import { useHangarAutocomplete } from "../Hangar/useHangarAutocomplete";
import { AddTenantButton } from "../Settings/HangarSettings/AddTenantButton";
import { HOLDING_AREA_ID } from "./HoldingArea";

type Props = {
  active: boolean;
  feetToPixels: number;
  multiHangarDepth: number;
  customPlacementOptions: CustomPlacementOptions;
};

export const HoldingAreaPresenter: React.FC<Props> = ({
  active,
  feetToPixels,
  multiHangarDepth,
  customPlacementOptions,
}) => {
  const theme = useTheme();
  const { sendToLocation } = useLocationsState();
  const { hangars, setHangars } = useMultiHangarState();
  const { towingEquipments: allTowingEquipments } = useTowingEquipmentState();
  const defaultTowingEquipmentId = allTowingEquipments.find(
    (t) => t.in_use && t.is_default
  )?.towing_equipment_id;

  const refAddAircraft = React.useRef<HTMLDivElement>(null);
  const { getHangar, setHangar } = useMultiHangarState();
  const hangar = getHangar(HOLDING_AREA_ID);
  // we hardcode to 250 b/c it doesn't really matter. arbitrary but works in most situations.
  const width = 250 / feetToPixels;
  // this is the same as 100% of the total height of the multi-layout container. we then need to offset
  // by the height of the 'Add Aircraft' button
  const buttonHeightOffsetPixels =
    refAddAircraft?.current?.clientHeight + parseFloat(theme.spacing(3)) || 0;
  // bottom of the bottom hangar has theme(2) of padding
  const bottomMarginOffset = parseFloat(theme.spacing(2));
  const depth =
    (multiHangarDepth - buttonHeightOffsetPixels - bottomMarginOffset) /
    feetToPixels;
  const previousDepth = usePrevious<number>(depth);

  const syntheticLocation = {
    type: "hangar",
    ...hangar,
    width,
    depth,
  };

  const setStack = React.useCallback(
    (stack: StackType) => {
      setHangar({
        ...hangar,
        stack: {
          ...hangar.stack,
          ...stack,
        },
      });
    },
    [hangar, setHangar]
  );

  const onAddAircraft = React.useCallback(
    (tenant: Tenant) => {
      const tenantWithPosition: Tenant = {
        ...tenant,
        position: {
          ...tenant.position,
          x: null,
          y: null,
          angle: null,
        },
      };
      setStack({
        ...hangar?.stack,
        tenants: [tenantWithPosition, ...hangar?.stack?.tenants],
      });
    },
    [hangar?.stack, setStack]
  );

  const autocomplete = useHangarAutocomplete(
    {
      hangar,
      defaultTowingEquipmentId,
      width,
      customPlacementOptions,
    },
    [hangar, customPlacementOptions, defaultTowingEquipmentId]
  );

  // watch for when the dimensions of the holding area change and then update the locations
  // of the aircraft so they remain in the same location
  React.useEffect(() => {
    const depthAdjustment = depth - previousDepth;
    setStack({
      ...hangar.stack,
      tenants: hangar.stack.tenants.map((t) => ({
        ...t,
        position: {
          ...t.position,
          y: t.position.y + depthAdjustment,
        },
      })),
      movableObstacles: hangar.stack.movableObstacles.map((o) => ({
        ...o,
        position: {
          ...o.position,
          y: o.position.y + depthAdjustment,
        },
      })),
    });
  }, [depth]);

  if (!hangar || !hangar?.stack) {
    return null;
  }

  return (
    <Stack
      direction="column"
      spacing={1}
      alignItems="center"
      sx={{ width: "100%" }}
    >
      <Box ref={refAddAircraft} sx={{ p: 1 }}>
        <Stack direction="column" alignItems="center" spacing={1}>
          <Typography>{"Holding Area"}</Typography>
          <AddTenantButton
            sendToLocation={sendToLocation}
            location={{ type: "hangar", ...hangar }}
            stack={hangar?.stack}
            setStack={(stack) => {
              setHangar({
                ...hangar,
                stack: {
                  ...hangar.stack,
                  ...stack,
                },
              });
            }}
            onAdd={onAddAircraft}
          />
        </Stack>
      </Box>
      <Box
        onMouseDown={() => {
          setHangars(
            hangars.map((h) => ({
              ...h,
              stack: {
                ...h.stack,
                tenants: h.stack.tenants.map((t) => ({
                  ...t,
                  selected: h.id !== HOLDING_AREA_ID ? false : t.selected,
                })),
                movableObstacles: h.stack.movableObstacles.map((mo) => ({
                  ...mo,
                  selected: h.id !== HOLDING_AREA_ID ? false : mo.selected,
                })),
              },
            }))
          );
        }}
      >
        <Layout
          width={250}
          location={syntheticLocation}
          stack={hangar?.stack}
          setStack={setStack}
          autocomplete={autocomplete}
          options={{
            hideUnplaced: false,
            readOnly: false,
            printable: false,
            selectable: false,
            disableDragProtection: true,
            backgroundColor: active ? "#d3d3d3" : "#b3b3b3",
            borderColor: active ? "black" : null,
            dashColor: "black",
          }}
        />
      </Box>
    </Stack>
  );
};

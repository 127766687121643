import { Box } from "@mui/material";
import * as React from "react";
import { drawSpacingBuffers } from "../../screens/Settings/HangarSettings/ObstacleCanvasDrawers";
import { Hangar } from "../../types";
import Canvas from "../../widgets/useCanvas";

type Props = {
  selectable: boolean;
  width: number;
  height: number;
  hangar: Hangar;
  feetToPixels: number;
  backgroundColor: string;
  dashColor: string;
  borderColor?: string;
  onClick: (evt: React.MouseEvent<HTMLElement>) => void;
  onMouseDown: (evt: React.MouseEvent<HTMLElement>) => void;
  onMouseUp: (evt: React.MouseEvent<HTMLElement>) => void;
  onMouseMove: (evt: React.MouseEvent<HTMLElement>) => void;
  onMouseEnter: (evt: React.MouseEvent<HTMLElement>) => void;
  onContextMenu: (evt: React.MouseEvent<HTMLElement>) => void;
  children?: React.ReactNode;
};

export const HangarLayoutBackground = React.forwardRef((props: Props, ref) => {
  const {
    width,
    height,
    hangar,
    feetToPixels,
    onClick,
    onMouseDown,
    onMouseUp,
    onMouseMove,
    onMouseEnter,
    onContextMenu,
    ...otherProps
  } = props;
  const gridSize = (width > 600 ? 10 : 10) * feetToPixels;
  const dashLength = gridSize / 10;
  const dashWidth = dashLength / 4;
  const backgroundColor = otherProps.backgroundColor ?? "#c8cfde";
  const borderColor =
    otherProps.borderColor ?? otherProps.dashColor ?? "#7a94bb";
  const dashColor = otherProps.dashColor ?? "#7a94bb";
  const canvasRef = React.createRef();
  const draw = React.useCallback(
    (ctx: CanvasRenderingContext2D) => {
      ctx.clearRect(0, 0, width, height);
      drawSpacingBuffers(ctx, hangar, (x) => x * feetToPixels, {
        lineWidth: width > 600 ? 1.5 : 1,
        lineDash: width > 600 ? null : [dashLength * 2, dashLength * 2],
      });
    },
    [hangar, height]
  );

  return (
    <Box
      ref={ref}
      sx={{
        position: "relative",
        m: 2,
      }}
    >
      <Box
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseEnter={onMouseEnter}
        onContextMenu={onContextMenu}
        sx={{
          position: "absolute",
          // need to account for border here
          width: width,
          height: height,
        }}
      >
        {props.children}
      </Box>
      <Canvas
        ref={canvasRef}
        width={width}
        height={height}
        draw={draw}
        style={{
          outline: `2px solid ${borderColor}`,
          backgroundColor,
          backgroundSize: `${dashLength * 2}px ${dashLength *
            2}px, ${gridSize}px ${gridSize}px`,
          backgroundPosition: `${-dashWidth / 2}px ${-dashWidth / 2}px`,
          backgroundImage: `linear-gradient(to bottom, transparent ${dashLength}px, ${backgroundColor} ${dashLength}px),
                linear-gradient(to right, ${dashColor}50 ${(dashLength * 3) /
            5}px, transparent ${(dashLength * 3) / 5}px),
                linear-gradient(to right, transparent ${dashLength}px, ${backgroundColor} ${dashLength}px),
                linear-gradient(to bottom, ${dashColor}50 ${(dashLength * 3) /
            5}px, transparent ${(dashLength * 3) / 5}px)
              `,
        }}
      />
    </Box>
  );
});

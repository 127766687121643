import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useRampState } from "../../../../containers/RampStateContainer";
import { useRampsState } from "../../../../containers/RampsStateContainer";
import { useSnackbar } from "../../../../containers/SnackbarContainer";
import { Ramp } from "../../../../types";
import { RampCanvas } from "./RampCanvas";
import { RampEditorForm } from "./RampEditorForm";

type Props = {
  onClose: () => void;
};

export const RampEditorPresenter: React.FC<Props> = ({ onClose }) => {
  const { ramps, setRamps } = useRampsState();
  const { ramp, setRamp } = useRampState();
  const [showReferenceImage, setShowReferenceImage] = React.useState<boolean>(
    true
  );
  const [localRamp, setLocalRamp] = React.useState<Ramp>();
  const snackbar = useSnackbar();

  React.useEffect(() => {
    setLocalRamp(ramp);
  }, [ramp]);

  const handleClose = () => {
    onClose();
  };

  const onClickSaveRamp = () => {
    setRamp(localRamp);
    setRamps(ramps.map((r) => (r.id === localRamp.id ? localRamp : r)));
    snackbar.notify({ text: "Ramp changes saved.", severity: "success" });
  };
  /**
   * make a ramp
   * give it a name
   * upload an image
   * make a reference line to tell us how many pixels = how many feet
   * add an outline
   * add other markings
   */

  if (!localRamp) {
    return null;
  }

  return (
    <Dialog
      open={true}
      maxWidth="xl"
      onClose={onClose}
      sx={{ maxHeight: "95vh" }}
      PaperProps={{ sx: { maxHeight: "100%" } }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Ramp Building Wizard</Typography>

          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ maxHeight: "100%", maxWidth: "100%" }}>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={2} alignItems="space-between">
            <Box sx={{ pt: 1, width: "30%" }}>
              <RampEditorForm
                ramp={localRamp}
                setRamp={setLocalRamp}
                onClickSaveRamp={onClickSaveRamp}
                onClose={onClose}
                showReferenceImage={showReferenceImage}
                setShowReferenceImage={setShowReferenceImage}
              />
            </Box>
            <Stack direction="column" spacing={1} sx={{ maxWidth: "70%" }}>
              <RampCanvas
                showReferenceImage={showReferenceImage}
                ramp={localRamp}
                setRamp={setLocalRamp}
              />
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

import * as React from "react";
import { convertDegreesToRadians, convertRadiansToDegress } from "./math/math";
import draggableAircraftRegistry from "./misc/draggableAircraftRegistry";
import { Aircraft, MinimalAircraft, Position } from "./types";
import DraggableAircraftNativeJS from "./ui/DraggablePlane";

type Props = {
  entity_id: string;
  tenantType?: string;
  readOnly?: boolean;
  hideDragIndicator?: boolean;
  label?: string;
  hotkey?: string;
  note?: string;
  labelWidth?: number;
  forceDisplayLabel?: boolean;
  aircraft: Aircraft;
  imageDimensions: {
    width: number;
    height: number;
  };
  onPositionChange: (position: Position) => void;
  onChange?: (position: Position) => void;
  onPlaneClick: () => void;
  onPlaneRightClick: (evt) => void;
  onStartTowing: () => void;
  spacingBufferOverlayElement: React.ReactNode;
  feetToPixels: number;
};

const { useEffect, useRef } = React;

export const DraggableAircraft: React.FC<Props> = ({
  entity_id,
  tenantType = "transient",
  readOnly = false,
  hideDragIndicator = false,
  forceDisplayLabel = false,
  label = "N/A",
  hotkey,
  note,
  labelWidth,
  aircraft,
  imageDimensions,
  onPositionChange,
  onChange = () => {},
  onPlaneClick,
  onPlaneRightClick,
  onStartTowing,
  spacingBufferOverlayElement,
  feetToPixels,
  ...props
}) => {
  // This reference is used for the DOM div element that we'll render the drag
  // UI instance into.
  var planeRefElement = useRef();
  // This ref is used to keep hold of the drag UI instance. Technically
  // we could probably use one ref but I think it's better to use each ref for
  // only one purpose
  var planeRef = useRef({
    instance: null,
    // We use this attribute as a way to prevent old React state overriding
    // the current aircraft position, as set by the user.
    // As React state updates asynchonously
    pendingChangeTimestamp: aircraft.position.timestamp,
  }).current;

  const onInstanceChange = React.useCallback(
    (event) => {
      var timestamp = new Date().valueOf();
      planeRef.pendingChangeTimestamp = timestamp;
      var position: Position = {
        x: event.x,
        y: event.y,
        angle: convertRadiansToDegress(event.angle),
        timestamp,
      };
      onPositionChange(position);
    },
    [onPositionChange]
  );

  const onIncrementalChange = React.useCallback(
    (event) => {
      var timestamp = new Date().valueOf();
      planeRef.pendingChangeTimestamp = timestamp;
      var position: Position = {
        x: event.x,
        y: event.y,
        angle: convertRadiansToDegress(event.angle),
        timestamp,
      };
      onChange(position);
    },
    [onChange]
  );

  useEffect(() => {
    var aircraftDto: MinimalAircraft = {
      type: tenantType,
      entity_id: entity_id,
      make: aircraft.make,
      model: aircraft.model,
      width: imageDimensions.width,
      height: imageDimensions.height,
      front_wheels: (aircraft.front_wheels * imageDimensions.height) / 600,
      rear_wheels: (aircraft.rear_wheels * imageDimensions.height) / 600,
      image: aircraft.image,
      position: {
        // NOTE: modified for the new data model. `position` is { x, y, angle }
        //x: aircraft.position.x * feetToPixels,
        //y: aircraft.position.y * feetToPixels,
        x: aircraft.position.x,
        y: aircraft.position.y,
        angle: convertDegreesToRadians(aircraft.position.angle),
      },
      wheel_rotation_limit: aircraft.wheel_rotation_limit,
      geom: aircraft.geom,
      tail_number: aircraft.tail_number,
      tail_height: aircraft.tail_height,
      // The scaled geom version is 180° rotated compared with the 'geom' so
      // this normalises it to be the same orientation
      //spacing_buffer_geom: rotatePolygon(aircraft.geom_scaled_buffer, Math.PI),
      spacing_buffer_geom: aircraft.spacing_buffer_geom,
      spacing_buffer_geom_dimensions: aircraft.spacing_buffer_geom_dimensions,
    };
    // const aircraftDto = makeMinimalAircraft(aircraft, imageDimensions);

    planeRef.instance = new DraggableAircraftNativeJS(aircraftDto, {
      selected: aircraft.selected,
      readOnly,
      forceDisplayLabel,
      label,
      hotkey,
      labelWidth,
      hideDragIndicator,
      spacingBufferOverlay: spacingBufferOverlayElement,
      note,
    });
    draggableAircraftRegistry.addAircraft(planeRef.instance);
    planeRef.instance.render(planeRefElement.current);

    return () => {
      draggableAircraftRegistry.removeAircraft(planeRef.instance);
      planeRef.instance.destroy();
    };
  }, [
    // If we change the aircraft completely tear it down and rebuild it
    aircraft?.id,
  ]);

  useEffect(() => {
    if (!planeRef.instance) return;
    var aircraftDto: MinimalAircraft = {
      type: tenantType,
      entity_id: entity_id,
      make: aircraft.make,
      model: aircraft.model,
      width: imageDimensions.width,
      height: imageDimensions.height,
      front_wheels: (aircraft.front_wheels * imageDimensions.height) / 600,
      rear_wheels: (aircraft.rear_wheels * imageDimensions.height) / 600,
      image: aircraft.image,
      // We handle setting the aircraft position slightly differently, as
      // there's a check that isn't covered here to ensure we're not setting
      // the position to out of date state. More info in the `useEffect` hook
      // below.
      /*position: {
        //x: aircraft.position.x * feetToPixels,
        //y: aircraft.position.y * feetToPixels,
        x: aircraft.position.x,
        y: aircraft.position.y,
        angle: convertDegreesToRadians(aircraft.position.angle),
      },*/
      wheel_rotation_limit: aircraft.wheel_rotation_limit,
      geom: aircraft.geom,
      tail_number: aircraft.tail_number,
      tail_height: aircraft.tail_height,
      spacing_buffer_geom: aircraft.spacing_buffer_geom,
      spacing_buffer_geom_dimensions: aircraft.spacing_buffer_geom_dimensions,
    };
    // const aircraftDto = makeMinimalAircraft(aircraft, imageDimensions);
    planeRef.instance.updateAircraft(aircraftDto);

    planeRef.instance.addEventListener("change", onIncrementalChange);
    // NOTE: changed the event to on dragEnd. this isn't neccessary depending on the
    // implementation.
    planeRef.instance.addEventListener("start", onStartTowing);
    planeRef.instance.addEventListener("dragEnd", onInstanceChange);
    planeRef.instance.addEventListener("rotateEnd", onInstanceChange);
    planeRef.instance.addEventListener("click", onPlaneClick);
    planeRef.instance.addEventListener("rightClick", onPlaneRightClick);

    return () => {
      planeRef.instance.removeEventListener("change", onIncrementalChange);
      // NOTE: changed the event to on dragEnd. this isn't neccessary depending on the
      // implementation.
      planeRef.instance.removeEventListener("start", onStartTowing);
      planeRef.instance.removeEventListener("dragEnd", onInstanceChange);
      planeRef.instance.removeEventListener("rotateEnd", onInstanceChange);
      planeRef.instance.removeEventListener("click", onPlaneClick);
      planeRef.instance.removeEventListener("rightClick", onPlaneRightClick);
    };
  }, [
    aircraft,
    imageDimensions,
    // We have to reattach the event listeners if the feet to pixels ratio
    // changes because otherwise the event listener will be attached with the
    // wrong ratio in scope
    //feetToPixels,
  ]);

  /**
   * The feetToPixels value changes when React is doing multiple renders
   * and figuring out what the correct ratio should be. In this scenario
   * we need to reset the aircraft position.
   * That said we don't want to set the aircraft position when the user
   * is interacting with the diagram because React's state update is async
   * so this can mean we're setting the aircraft to a stale position.
   * What the user does is the source of truth, so there's no need to
   * set it to a different value.
   */
  useEffect(() => {
    if (!planeRef.instance) return;
    // Due to React's state update cycle being async, the user may have
    // triggered a new position change before the position change comes
    // full cycle and passed back here.
    // Without this check we could end up setting the aircraft to an old / stale
    // position
    if (
      planeRef.pendingChangeTimestamp &&
      aircraft.position.timestamp &&
      planeRef.pendingChangeTimestamp == aircraft.position.timestamp
    ) {
      // React has caught up, clear out the timestamp, if we don't
      // then the undo functionality won't work
      // because when tapping undo the pending change would otherwise always
      // be old
      delete planeRef.pendingChangeTimestamp;
      return;
    }
    if (
      planeRef.pendingChangeTimestamp &&
      aircraft.position.timestamp &&
      planeRef.pendingChangeTimestamp > aircraft.position.timestamp
    ) {
      // A user triggered an update faster than react re-renders, so we'll
      // ignore running this because it will set the aircraft to an old
      // position
      return;
    }
    planeRef.instance.updateAircraft({
      position: {
        x: aircraft.position.x,
        y: aircraft.position.y,
        angle: convertDegreesToRadians(aircraft.position.angle),
      },
    });
  }, [aircraft, feetToPixels]);

  useEffect(() => {
    if (!planeRef.instance) return;
    planeRef.instance.setSelected(aircraft?.selected);
  }, [aircraft?.selected]);

  useEffect(() => {
    if (!planeRef.instance) return;
    planeRef.instance.setForceDisplayLabel(forceDisplayLabel);
  }, [forceDisplayLabel]);

  useEffect(() => {
    if (!planeRef.instance) return;
    planeRef.instance.setLabel(label);
  }, [label]);

  useEffect(() => {
    if (!planeRef.instance) return;
    planeRef.instance.setHotkey(hotkey);
  }, [hotkey]);

  useEffect(() => {
    if (!planeRef.instance) return;
    planeRef.instance.setNote(note);
  }, [note]);

  useEffect(() => {
    if (!planeRef.instance) return;
    planeRef.instance.setLabelWidth(labelWidth);
  }, [labelWidth]);

  useEffect(() => {
    if (!planeRef.instance) return;
    planeRef.instance.setSpacingBufferOverlay(spacingBufferOverlayElement);
  }, [spacingBufferOverlayElement]);

  const name = Boolean(aircraft.make)
    ? `${aircraft.make} ${aircraft.model}`
    : `${label}`;

  return (
    <div
      data-attr={name}
      style={{
        zIndex: 100 + Math.ceil(100 * (aircraft.tail_bottom ?? 0)),
      }}
      ref={planeRefElement}
    ></div>
  );
};

import * as React from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography
  } from "@mui/material";
import { DropImage } from "../../../LabelingTool/DropImage";
import { Ramp } from "../../../../types";
import { RampCalibrationCanvas } from "./RampCalibrationCanvas";
import { useActiveFBOs } from "../../../../containers/ActiveFBOContainer";
import { v4 as uuidv4 } from "uuid";

type Props = {
  onSave: (ramp: Ramp) => void;
  onClose: () => void;
};

export type ImageDimensions = {
  width: number;
  height: number;
};

export const RampInitialize: React.FC<Props> = ({ onSave, onClose }) => {
  const { activeFBO } = useActiveFBOs();
  const [ramp, setRamp] = React.useState<Ramp>({
    id: uuidv4(),
    name: "",
    fbo_id: activeFBO.id,
    reference_image: null,
    width: null,
    depth: null,
    markings: [],
    outline: null,
  });
  const [imageDimensions, setImageDimensions] = React.useState<ImageDimensions>(
    {
      width: null,
      height: null,
    }
  );

  React.useEffect(() => {
    if (!ramp?.reference_image) {
      return;
    }
    const img = new Image();
    img.src = ramp?.reference_image;
    img.onload = () => {
      setImageDimensions({
        width: img.width,
        height: img.height,
      });
    };
  }, [ramp?.reference_image]);

  const onUploadImage = React.useCallback(
    (base64Image: string) => {
      setRamp({
        ...ramp,
        reference_image: base64Image,
      });
    },
    [ramp]
  );

  /**
   * give it a name
   * upload an image
   * make a reference line to tell us how many pixels = how many feet
   */

  return (
    <Stack
      direction="column"
      spacing={1}
      alignItems="center"
      sx={{ maxWidth: "100%" }}
    >
      <Typography variant="h6">Create New Ramp</Typography>
      <Stack direction="column" spacing={1}>
        <TextField
          fullWidth
          size="small"
          label="Ramp Name"
          onChange={(evt) => {
            setRamp({ ...ramp, name: evt.target.value });
          }}
          value={ramp?.name ?? ""}
        />
        <Box>
          {!ramp?.reference_image ? (
            <DropImage
              label={"Ramp Reference Image"}
              onUploadImage={onUploadImage}
              displayPreview={false}
              style={{ width: 500, height: 500 }}
            />
          ) : (
            <Stack direction="column" spacing={1} alignItems="center">
              <RampCalibrationCanvas
                imageDimensions={imageDimensions}
                ramp={ramp}
                setRamp={setRamp}
                showReferenceImage={false}
              />
              {!ramp?.width ? (
                <Typography>
                  Please create a reference line on the image. We'll use this to
                  calibrate the scale of your ramp from pixels to feet.
                </Typography>
              ) : (
                <Typography></Typography>
              )}
            </Stack>
          )}
        </Box>
      </Stack>
      {ramp?.reference_image && ramp.width && (
        <Stack direction="row" justifyContent="flex-end" sx={{ width: "100%" }}>
          <Button
            color="error"
            onClick={() => {
              setRamp({
                ...ramp,
                reference_image: null,
                width: null,
                depth: null,
              });
            }}
          >
            Redo
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSave(ramp)}
          >
            Looks Good
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

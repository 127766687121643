import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { round } from "lodash";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { Aircraft } from "../../LabelingTool";
import { AutocompleteAircraft } from "../HangarSettings/AutocompleteAircraft";

const calculateSqftPrice = (
  aircraft: Aircraft,
  rate: number,
  type: string
): number => {
  if (type === "base") {
    return rate / (aircraft.length * aircraft.wingspan) / 30;
  }
  // otherwise it's nightly
  return rate / (aircraft.length * aircraft.wingspan);
};

export const RatesTab: React.FC = () => {
  const { activeFBO, setFBO } = useActiveFBOs();

  const monthlyRate = React.useMemo(
    () => (activeFBO.nightly_base_rate * 30).toFixed(2),
    []
  );
  const nightlyRate = React.useMemo(
    () => activeFBO.nightly_transient_rate.toFixed(2),
    []
  );

  return (
    <Stack direction="column" spacing={4}>
      <Stack direction="row" spacing={2}>
        <Stack direction="column" spacing={1}>
          <Typography>Base Tenant Rate</Typography>
          <TextField
            label="$ / sqft / month"
            variant="outlined"
            type="number"
            size="small"
            inputProps={{
              step: 0.01,
              min: 0.01,
              max: 100,
              inputMode: "decimal",
            }}
            sx={{ width: 200 }}
            onChange={(evt) => {
              const newRate = Number(evt.target.value) / 30;
              if (isNaN(newRate)) {
                return;
              }
              setFBO({
                ...activeFBO,
                nightly_base_rate: newRate,
              });
            }}
            defaultValue={monthlyRate}
          />
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography>Transient Rate</Typography>
          <TextField
            label="$ / sqft / day"
            variant="outlined"
            type="number"
            size="small"
            inputProps={{
              step: 0.01,
              min: 0.01,
              max: 100,
              inputMode: "decimal",
            }}
            sx={{ width: 200 }}
            onChange={(evt) => {
              const newRate = round(Number(evt.target.value), 2);
              if (isNaN(newRate)) {
                return;
              }
              setFBO({
                ...activeFBO,
                nightly_transient_rate: newRate,
              });
            }}
            defaultValue={nightlyRate}
          />
        </Stack>
      </Stack>
      <RateCalculator />
    </Stack>
  );
};

export const RateCalculator: React.FC = () => {
  const [aircraft, setAircaft] = React.useState<Aircraft | null>(null);
  const [rate, setRate] = React.useState<number>(0);
  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
        <Typography textAlign="center">
          Help Calculate My Current Rates
        </Typography>
        <Tooltip
          title={`If you don't know your typical monthly tenant or nightly transient rates, use this calculator to figure them out. Just enter an example aircraft and the total fee you currently charge to see what that equates to in monthly or daily rates.`}
        >
          <InfoIcon fontSize="small" />
        </Tooltip>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{
          borderBottom: "1px solid #ccc",
        }}
      >
        <Typography width={300}>Aircraft</Typography>
        <Typography width={175}>Length x Wingspan</Typography>
        <Typography width={175}>Total Sqft</Typography>
        <Typography width={200}>Total Fee</Typography>
        <Typography width={125}>$/sqft</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box width={300}>
          <AutocompleteAircraft label={"Make/Model"} onSelect={setAircaft} />
        </Box>
        <Typography width={175}>
          {aircraft
            ? `${round(aircraft.length, 0)}L x ${round(aircraft.wingspan)}WS`
            : "---"}
        </Typography>
        <Typography width={175}>
          {aircraft
            ? `${round(aircraft.length * aircraft.wingspan, 0)} sqft`
            : "---"}
        </Typography>
        <TextField
          type="number"
          inputProps={{ min: 0.01, max: 100, step: 0.01, inputMode: "decimal" }}
          label="Rate"
          fullWidth
          size="small"
          onChange={(evt) => {
            setRate(round(parseFloat(evt.target.value), 2));
          }}
          value={rate}
          sx={{ width: 200 }}
        />
        <Typography width={125}>
          {!aircraft || !rate
            ? "---"
            : `$${round(calculateSqftPrice(aircraft, rate, "transient"), 3)}`}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const FeeCalculator: React.FC<{
  defaultMonthlyRate?: number;
  defaultDailyRate?: number;
}> = ({ defaultMonthlyRate, defaultDailyRate }) => {
  const [aircraft, setAircaft] = React.useState<Aircraft | null>(null);
  const [buffer, setBuffer] = React.useState<number>(0);
  const [monthlyRate, setMonthlyRate] = React.useState<number>(
    defaultMonthlyRate ?? 0
  );
  const [dailyRate, setDailyRate] = React.useState<number>(
    defaultDailyRate ?? 0
  );

  return (
    <Stack direction="column" spacing={1}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{
          borderBottom: "1px solid #ccc",
        }}
      >
        <Typography width={300}>Aircraft</Typography>
        <Typography width={175}>Length x Wingspan</Typography>
        <Typography width={100}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="inherit">Buffer</Typography>
            <Tooltip title="A buffer is added to each end of the dimensions. Eg: a 2ft buffer adds a total of 4ft to the length and 4ft to the wingspan.">
              <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
            </Tooltip>
          </Stack>
        </Typography>
        <Typography width={175}>Total Sqft</Typography>
        <Typography width={200}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="inherit">Rate</Typography>
            <Tooltip title="Enter your desired rates below. You can also change the default rates by going to Settings → Estimation→Rates.">
              <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
            </Tooltip>
          </Stack>
        </Typography>
        <Typography width={125}>Total</Typography>
      </Stack>
      <Stack direction="row" alignItems="flex-start" spacing={1}>
        <Box width={300}>
          <AutocompleteAircraft label={"Make/Model"} onSelect={setAircaft} />
        </Box>
        <Typography width={175} height={40} pt={1}>
          {aircraft
            ? `${round(aircraft.length, 1).toFixed(1)}L x ${round(
                aircraft.wingspan,
                1
              ).toFixed(1)}WS`
            : "---"}
        </Typography>
        <TextField
          type="number"
          inputProps={{ min: 0.0, max: 5, step: 0.5, inputMode: "decimal" }}
          label=""
          size="small"
          onChange={(evt) => {
            setBuffer(Number(evt.target.value));
          }}
          value={buffer}
          sx={{ width: 100 }}
        />
        <Typography width={175} height={40} pt={1}>
          {aircraft
            ? `${round(
                (2 * buffer + aircraft.length) *
                  (2 * buffer + aircraft.wingspan),
                0
              )} sqft`
            : "---"}
        </Typography>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1}>
            <TextField
              type="number"
              inputProps={{
                min: 1,
                max: 100,
                step: 0.01,
                inputMode: "decimal",
              }}
              label="$ / sqft / month"
              size="small"
              onChange={(evt) => {
                setMonthlyRate(round(parseFloat(evt.target.value), 2));
              }}
              value={monthlyRate}
              sx={{ width: 200 }}
            />
            <Typography width={125} pt={1}>
              {!aircraft || !monthlyRate
                ? "---"
                : `$${round(
                    monthlyRate *
                      (2 * buffer + aircraft.length) *
                      (2 * buffer + aircraft.wingspan),
                    2
                  )}`}
            </Typography>
            <Typography width={175} pt={1} textAlign="left">
              Tenant (monthly)
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <TextField
              type="number"
              inputProps={{
                min: 0.01,
                max: 100,
                step: 0.01,
                inputMode: "decimal",
              }}
              label="$ / sqft / day"
              size="small"
              onChange={(evt) => {
                setDailyRate(round(parseFloat(evt.target.value), 2));
              }}
              value={dailyRate}
              sx={{ width: 200 }}
            />
            <Typography width={125} pt={1} textAlign="left">
              {!aircraft || !dailyRate
                ? "---"
                : `$${round(
                    dailyRate *
                      (2 * buffer + aircraft.length) *
                      (2 * buffer + aircraft.wingspan),
                    2
                  )}`}
            </Typography>
            <Typography width={150} pt={1}>
              Transient (daily)
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const RevenueEstimatorPresenter: React.FC<{}> = ({}) => {
  const [activeTab, setActiveTab] = React.useState<string>("rates");
  const { activeFBO } = useActiveFBOs();

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ height: "calc(100% - 150px)", width: "100%" }}
    >
      <Stack direction="column" spacing={1}>
        <Tabs
          value={activeTab}
          onChange={(evt, newValue) => setActiveTab(newValue)}
        >
          <Tab label="Rates" value="rates" />
          <Tab label="Fee Calculator" value="fee_calculator" />
        </Tabs>
        {activeTab === "rates" ? (
          <RatesTab />
        ) : (
          <FeeCalculator
            defaultMonthlyRate={activeFBO.nightly_base_rate * 30}
            defaultDailyRate={activeFBO.nightly_transient_rate}
          />
        )}
      </Stack>
    </Stack>
  );
};
